<template>
  <div class="index-wrapper">
    <div>
      <div class="banner">
        <swiper
          ref="mySwiper"
          :options="bannerSwiper.swiperOptions"
          class="banner-swiper"
          @ready="swiperLoad('bannerSwiper', 'mySwiper')"
          @slideChangeTransitionEnd="slideChnage('bannerSwiper')"
        >
          <template
            v-for="(item, index) in (this.$i18n.locale === 'zh' ? bannerList : bannerListEn)"
          >
            <swiper-slide
              class="banner-swiper-slide"
              :key="index"
            >
              <div class="banner-swiper-slide-dev">
                <img :src="item" />
              </div>
            </swiper-slide>
          </template>
        </swiper>
      </div>
      <div class="section section-one">
        <div class="section-title">
          {{ $t('indexSectionOne.title') + '-' +  $t('indexSectionOne.one') }}
        </div>
        <div>{{ $t('indexSectionOne.two') }}</div>
        <div>
          <p>{{ $t('indexSectionOne.three') }}</p>
          <p>{{ $t('indexSectionOne.four') }}</p>
          <p>{{ $t('indexSectionOne.five') }}</p>
        </div>
        <div @click="visible = true" class="open-dialog">
          <img src="@/assets/image/icon/1.png" alt="">
          {{ $t('了解天窗灯') }}
        </div>
      </div>
      <div class="section section-two">
        <div class="section-title">
           {{ $t('自然的光')}}
        </div>
        <div class="section-two-nav">
          <div @click="sectionTwoActve = 0" :class="sectionTwoActve === 0 && 'active'"><div>{{ $t('indexSectionTwo.one') }}</div></div>
          <div @click="sectionTwoActve = 1" :class="sectionTwoActve === 1 && 'active'"><div>{{ $t('indexSectionTwo.three') }}</div></div>
          <div @click="sectionTwoActve = 2" :class="sectionTwoActve === 2 && 'active'">
            <div>{{ $t('indexSectionTwo.five') }}</div>
          </div>
        </div>
        <div class="section-two-content">
          <div
            v-show="sectionTwoActve === 0"
          >
            <el-image
              fit="cover"
              :src="sectionTwo[2]"
              :preview-src-list="[sectionTwo[2]]">
            >
              <div class="section-two-list-img-text" slot="error">{{ $t('imgError') }}</div>
            </el-image>
            <div class="section-two-content-content">{{ $t('indexSectionTwo.two') }}</div>
          </div>
          <div
            v-show="sectionTwoActve === 1"
          >
            <el-image
              fit="cover"
              :src="sectionTwo[0]"
              :preview-src-list="[sectionTwo[0]]">
            >
              <div class="section-two-list-img-text" slot="error">{{ $t('imgError') }}</div>
            </el-image>
            <div class="section-two-content-content">{{ $t('indexSectionTwo.four') }}</div>
          </div>
          <div
            v-show="sectionTwoActve === 2"
          >
            <el-image
              fit="cover"
              :src="sectionTwo[1]"
              :preview-src-list="[sectionTwo[1]]">
            >
              <div class="section-two-list-img-text" slot="error">{{ $t('imgError') }}</div>
            </el-image>
            <div class="section-two-content-content">{{ $t('indexSectionTwo.six') }}</div>
          </div>
        </div>
      </div>
      <div class="section section-three">
        <div class="section-title">
          {{ $t('健康的光') }}
        </div>
        <div class="section-three-content">
          <div>
            <el-image
              :src="sectionThree[0]"
            >
              <div class="section-two-list-img-text" slot="error">{{ $t('imgError') }}</div>
            </el-image>
            <div>
              <div>{{ $t('indexSectionThree.two') }}</div>
              <div>{{ $t('indexSectionThree.three') }}</div>
            </div>
          </div>
          <div>
            <el-image
              :src="sectionThree[1]"
            >
              <div class="section-two-list-img-text" slot="error">{{ $t('imgError') }}</div>
            </el-image>
            <div>
              <div>{{ $t('indexSectionThree.four') }}</div>
              <div>{{ $t('indexSectionThree.five') }}</div>
            </div>
          </div>
        </div>
      </div>
      <div class="section section-four">
        <div class="section-title">
          {{ $t('全新的应用场景') }}
        </div>
        <div class="section-four-swiper">
          <swiper
            :options="sectionFourSwiper.swiperOptions"
          >
            <template
              v-for="(item, index) in sectionFour"
            >
              <swiper-slide
                class="section-four-swiper-slide"
                :key="index"
                @click.native="$router.push(item.link)"
              >
                  <img :src="item.imgage" />
                  <div class="section-four-swiper-slide-html" v-html="$t(item.prop)"></div>
              </swiper-slide>
            </template>
          </swiper>
        </div>
      </div>
      <div class="section section-five">
        <div class="section-title">
          {{ $t('news') }}
        </div>
        <div class="section-five-swiper">
          <swiper
            :options="sectionFiveSwiper.swiperOptions"
          >
            <template
              v-for="(item, index) in computedList"
            >
              <swiper-slide
                class="section-five-swiper-slide"
                :key="index"
              >
                <div @click="newsClick(item)" class="section-five-side-wrapper">
                  <div>
                    <img :src="item.cover" />
                  </div>
                  <div>
                    <div>{{ item.title }}</div>
                    <div>{{ item.desction }}</div>
                    <div>{{ time(item.show_time.split(' ')[0]) }}</div>
                  </div>
                </div>
              </swiper-slide>
            </template>
            
          </swiper>
          <div class="section-five-pagination"></div>
        </div>
      </div>
      <el-dialog
        v-if="visible"
        :visible.sync="visible"
        width="80%"
      >
        <video
          v-if="this.$i18n.locale === 'zh'"
          autoplay
          controls
          src="@/assets/image/index-video.mp4"
        />
        <video
          v-else
          autoplay
          controls
          src="@/assets/image/video-en.mp4"
        />
      </el-dialog>
    </div>
  </div>

</template>

<script>
import { Swiper, SwiperSlide } from 'vue-awesome-swiper'
import { configIndex } from '@/utils/index'
import { formatTime, goGet } from '@/utils'
export default {
  name: 'Index',
  metaInfo() {
    return {
      title: this.$t('indexTitle')
    }
  },
  components:{
    Swiper,
    SwiperSlide
  },
  computed: {
    sectionFiveComputed() {
      return configIndex.sectionFive.filter(list => list.lang === this.$i18n.locale)
    },
    computedList() {
      return this.newsList.filter(list => list.lang === this.$i18n.locale)
    },
  },
  data() {
    const { bannerList, bannerListEn, sectionTwo, sectionThree, sectionFour } = configIndex
    return {
      sectionTwoActve: 0,
      isLoad: false,
      bannerList,
      bannerListEn,
      bannerSwiper: {
        swiper: null,
        swiperOptions: {
          loop: true,
          slidesPerView: "auto",
          spaceBetween: 10,
          centeredSlides: true,
        },
        activeIndex: null,
      },
      sectionOneImgae: configIndex.sectionOne[0],
      sectionTwo,
      sectionThree,
      sectionFour,
      sectionFourSwiper: {
        swiperOptions: {
          loop: false,
          autoplay: {
            delay: 1000,
            disableOnInteraction: false
          },
          slidesPerView: 'auto',
          spaceBetween: 10,
        }
      },
      sectionFiveSwiper: {
        swiperOptions: {
          loop: false,
          spaceBetween: 10,
          pagination: {
            el: ".section-five-pagination",
            type: "progressbar",
          },
        }
      },
      visible: false,
      newsList: [],
      videoUrl: process.env.VUE_APP_API + '/static/other/index-video.mp4'
    }
  },
  mounted() {
    this.getList()
  },
  methods: {
    time(date) {
      return formatTime('yearMonthDay', date)
    },
    getList() {
      goGet('/ewinlight/article/list', {}, false).then(res => {
        this.isLoad = true
        this.newsList = res.data.map(list => {
          list.cover = list.cover.replace('http://192.168.2.245:1500', process.env.VUE_APP_API)
          return list
        })
      })
    },
    newsClick(item) {
      this.$router.push('/article/' + item.id)
    },
    swiperLoad(prop, refName) {
      this[prop].swiper = this.$refs[refName].$swiper
      this[prop].activeIndex = this.getActiveIndex(prop)
    },
    getActiveIndex(prop) {
      return this[prop].swiper.realIndex + 1
    },
    slideChnage(prop) {
      this[prop].activeIndex = this.getActiveIndex(prop)
    }
  }
}
</script>

<style lang="scss" scoped>
.index-wrapper {
  width: 375px;
  background: rgba(245, 246, 247, 1);
  overflow: hidden;
  .el-image {
    ::v-deep img {
      transform: scale(1);
      transition: transform 0.3s;
      &:hover {
        transform: scale(1.04);
        transition: transform 0.3s;
      }
    }
  }
  .banner {
    width: 100%;
    height: 162px;
    overflow: hidden;
    position: relative;
    padding-bottom: 8px;
    background: #fff;
    .banner-swiper {
      width: 100%;
      height:162px;
      overflow: hidden;
       ::v-deep .banner-swiper-slide {
        width: 337px;
        overflow: hidden;
        border-radius: 15px;
        .banner-swiper-slide-dev {
          width: 337px;
          height: 162px;
          justify-content: center;
          overflow: hidden;
          align-content: center;
          align-items: center;
          display: flex;
          img {
            width: 600px;
          }
        }
      }
    }
  }

  .section {
    width: 375px;
    padding: 24px 20px;
    background: #fff;
    margin-bottom: 8px;
    font-size: 13px;
    color: #666666;
    line-height: 24px;
    overflow: hidden;
    .section-title {
      font-size: 20px;
      font-weight: bold;
      color: #222222;
      line-height: 36px;
    }
  }

  .section-one {
    div {
      &:nth-child(2) {
        margin: 12px 0;
      }
    }
    .open-dialog {
      width: 205px;
      height: 40px;
      border-radius: 20px;
      overflow: hidden;
      color: #fff;
      font-size: 14px;
      line-height: 40px;
      margin: 0 auto;
      margin-top: 24px;
      background: #333;
      display: flex;
      justify-content: center;
      align-items: center;
      img {
        width: 18px;
        margin-right: 9px;
      }
    }
  }

  .section-two {
    &-nav {
      margin-top: 14px;
      display: flex;
      justify-content: space-between;
      min-height: 28px;
      margin-bottom: 20px;
      align-content: center;
      & > div {
        padding: 6px 20px;
        height: 100%;
        font-size: 12px;
        font-weight: 400;
        color: rgba(51, 51, 51, 1);
        line-height: 20px;
        border-radius: 14px;
        background: rgba(240, 240, 240, 1);
        display: flex;
        justify-content: center;
        align-items: center;
        margin-right: 5px;
        &:last-child {
          margin-right: 0;
        }
        &.active {
          color: rgba(255, 255, 255, 1);
          background: #333333;
        }
      }
    }
    &-content {
      background: #FFFFFF;
      box-shadow: 0px 2px 10px 0px rgba(207, 207, 207, 0.5);
      border-radius: 10px;
      overflow: hidden;
      .el-image {
        border-radius: 10px 10px 0 0;
      }
      &-content {
        padding: 16px;
      }
    }
  }
  .section-three {
    overflow: auto;
    padding: 0;
    .section-title {
      padding-left: 24px;
      padding-top: 24px;
    }
    &-content {
      display: flex;
      width: 100%;
      overflow-x: scroll;
      box-sizing: border-box;
      padding: 24px;
      padding-top: 20px;
      justify-content: space-between;
      &>div {
        width: 305px;
        margin-right: 10px;
        flex-shrink: 0;
        overflow: hidden;
        border-radius: 10px;
        box-shadow: 0px 2px 10px 0px rgba(207, 207, 207, 0.5);
        &>div {
          padding: 16px;
          & > div {
            &:nth-child(1) {
              font-size: 16px;
              font-weight: 600;
              color: #222222;
              line-height: 24px;
              margin-bottom: 6px;
            }
          }
        }
        .el-image {
          padding: 0;
          border-radius: 10px 10px 0 0;
          overflow: hidden;
          display: block;
          img {
            width: 305px;
          }
        }
        &:last-child {
          margin-right: 0;
        }
      }
    }
  }
  .section-four {
    .section-four-swiper {
      width: 100%;
      margin-top: 20px;
      ::v-deep .section-four-swiper-slide {
        overflow: hidden;
        width: 150px;
        position: relative;
        cursor: pointer;
        border-radius: 10px;
        height: 180px;
        img {
          width: 100%;
          border-radius: 10px;
          overflow: hidden;
        }
        .section-four-swiper-slide-html {
          position: absolute;
          bottom: 0;
          left: 0;
          z-index: 1;
          width: 100%;
          height: 180px;
          padding: 10px;
          display: flex;
          flex-direction: column;
          justify-content: flex-end;
          background: url('~@/assets/image/section/s7.png');
          & > p {
            color: #fff;
            font-size: 16px;
            font-weight: bold;
            font-family: 'mySecondFont';
            &:nth-child(2) {
              font-weight: normal;
            }
          }
        }
      }
    }
  }
  .section-five {
    background: none;
    padding-bottom: 60px;
    .section-five-swiper {
      width: 100%;
      position: relative;
      margin-top: 16px;
      display: flex;
      ::v-deep .section-five-swiper-slide {
        .section-five-side-wrapper {
          width: 100%;
          height: 100%;
          background: #FFFFFF;
          border-radius: 10px;
          overflow: hidden;
          & > div {
            &:nth-child(1) {
              width: 100%;
              height: 188px;
              display: flex;
              overflow: hidden;
              img {
                width: 100%;
                object-fit: cover;
              }
            }
            &:nth-child(2) {
              padding: 20px;
              padding-top: 10px;
              & > div {
                &:nth-child(1) {
                  width: 100%;
                  font-size: 14px;
                  font-weight: bold;
                  color: #222222;
                  line-height: 28px;
                }
                &:nth-child(3) {
                  margin-top: 24px;
                  color: #999999;
                }
              }
            }
          }
        }
      }
    }
    &-pagination {
      width: 200px;
      height: 2PX;
      background: rgba(212, 212, 212, 1);
      margin: 0 auto;
      left: 67.5px;
      bottom: -30px;
      z-index: 10;
      ::v-deep .swiper-pagination-progressbar-fill{
        background: rgba(34, 34, 34, 1);
      }
    }
  }

  ::v-deep .el-dialog {
    height: 70vh;
    background: none;
    box-shadow: none;
    .el-dialog__close {
      background: #fff;
      border-radius: 50%;
      overflow: hidden;
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 2px;
      color: #333;
    }
    .el-dialog__header {
      width: 0;
      height: 0;
      padding: 0;
      .el-dialog__headerbtn {
        z-index: 1000;
      }
    }
    .el-dialog__body {
      padding: 0;
      background: none;
      display: flex;
      align-items: center;
      width: 100%;
      height: 100%;
      outline: none;
      video {
        width: 100%;
        height: 100%;
        outline: none;
      }
    }
  }
}
</style>